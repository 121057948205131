import EditIcon from '@/assets/edit_icon.svg';

class MapObjectFactory {
  constructor(api, map) {
    this.api = api;
    this.map = map;
  }

  buildEditMarker(latLng) {
    return this.buildMarker(latLng, EditIcon);
  }

  buildMarker(latLng, icon) {
    return new this.api.Marker({
      map: this.map,
      position: latLng,
      visible: true,
      icon: icon
        ? {
            scaledSize: new this.api.Size(32, 32, 'px', 'px'),
            url: icon,
            anchor: new this.api.Point(16, 16),
          }
        : null,
    });
  }

  buildLabelOverlay(labelText, latLng, { bg=null, fg=null, border=false, elementClass = null, targetPane = null, title = null, visible = true, zIndex=0 }) {
    let element = document.createElement('div');
    element.innerHTML = labelText;

    if(bg) {
      element.style.backgroundColor = bg;
    }
    if(fg) {
      element.style.color = fg;
    }
    if(border) {
      element.style.border = `2px solid ${fg || 'black'}`;
    }

    if(! elementClass) {
      element.classList.add('absolute');
    }
    else if (elementClass instanceof Array) {
      elementClass.forEach((c) => {
        element.classList.add(c);
      });
    } else {
      element.classList.add(elementClass);
    }

    if (title) {
      element.title = title;
    }

    let finalPane = targetPane ? targetPane : 'markerLayer';

    let ret = this.buildElementOverlay(element, latLng, {
      pane: finalPane,
      visible, zIndex
    });

    let oldClass = elementClass;

    let self = this; //throwback!
    ret.setOptions = function ({ labelText, latLng, elementClass, visible }) {
      if (labelText) {
        this.element.innerHTML = labelText;
      }
      if (latLng) {
        this.centerLatLng = new self.api.LatLng(latLng.lat, latLng.lng);
      }

      if (visible === false) {
        this.element.classList.add('hidden');
      } else {
        this.element.classList.remove('hidden');
      }

      if (elementClass) {
        if (oldClass) {
          if (oldClass instanceof Array) {
            oldClass.forEach((c) => this.element.classList.remove(c));
          } else {
            this.element.classList.remove(oldClass);
          }
        }

        if (elementClass instanceof Array) {
          elementClass.forEach((c) => this.element.classList.add(c));
        } else {
          this.element.classList.add(elementClass);
        }

        oldClass = elementClass;
      }

      this.recalcDims();
      this.draw();
    };

    return ret;
  }

  buildElementOverlay(element, geoPoint, options) {
    let ret = BuildElementOverlay(this.api, element, geoPoint, options);

    ret.setMap(this.map);

    return ret;
  }

  buildBoundedOverlay(element, googleLatLngBounds, options) {
    let ret = BuildBoundedOverlay(this.api, element, googleLatLngBounds, options);

    ret.setMap(this.map);

    return ret;
  }

  buildInfoWindow(content) {
    let contentWrapper = document.createElement('div');
    contentWrapper.innerHTML = content;
    contentWrapper.className += 'info_window_content';
    return new this.api.InfoWindow({
      content: contentWrapper,
    });
  }
}

let ElementOverlayClass = null;

function BuildElementOverlay(api, element, centerLatLng, options) {
  if (!ElementOverlayClass) {
    ElementOverlayClass = function (element, centerLatLng, pane) {
      this.element = element;
      this.centerLatLng = centerLatLng;
      this.pane = pane;
      this.width = 100;
      this.height = 50;

      if (!this.element) {
        throw new Error('Element Required');
      }
    };

    ElementOverlayClass.prototype = new api.OverlayView();

    ElementOverlayClass.prototype.getPosition = function () {
      return this.centerLatLng;
    };

    ElementOverlayClass.prototype.getVisible = function () {
      return true;
    };

    ElementOverlayClass.prototype.onAdd = function () {
      this.getPanes()[this.pane].appendChild(this.element);
      this.recalcDims();
    };

    ElementOverlayClass.prototype.onRemove = function () {
      this.element.remove();
    };

    ElementOverlayClass.prototype.draw = function () {
      if(this.map?.moving) {
        return;
      }

      let proj = this.getProjection();
      if (proj == null) {
        return;
      }

      let xy = proj.fromLatLngToDivPixel(this.centerLatLng);

      if(! this.element.style) {
        this.element.style = {};
      }
      let style = this.element.style;

      style.left = `${xy.x}px`;//Math.round(xy.x - this.width / 2).toString() + 'px';
      style.top = `${xy.y}px`; //Math.round(xy.y - this.height / 2).toString() + 'px';
    };

    ElementOverlayClass.prototype.recalcDims = function() {
      this.width = this.element.clientWidth;
      this.height = this.element.clientHeight;
    }

    ElementOverlayClass.prototype.setVisible = function (val) {
      if (val) {
        this.element.classList.remove('hidden');
      } else {
        this.element.classList.add('hidden');
      }
    };
  }

  let pane = options && options.pane ? options.pane : 'mapPane';

  const ret = new ElementOverlayClass(element, new api.LatLng(centerLatLng), pane);

  if (options && options.visible === false) {
    ret.element.classList.add('hidden');
  }

  return ret;
}

let BoundedOverlayClass = null;

function BuildBoundedOverlay(api, element, googleLatLngBounds, options) {
  if (!BoundedOverlayClass) {
    BoundedOverlayClass = function (element, googleLatLngBounds, pane) {
      this.element = element;
      this.bounds = googleLatLngBounds;
      this.pane = pane;
      this.elementWidth = null;
      this.elementHeight = null;

      if (!this.element) {
        throw new Error('Element Required');
      }
    };

    BoundedOverlayClass.prototype = new api.OverlayView();

    BoundedOverlayClass.prototype.onAdd = function () {
      this.getPanes()[this.pane].appendChild(this.element);
    };

    BoundedOverlayClass.prototype.onRemove = function () {
      this.element.remove();
    };

    BoundedOverlayClass.prototype.draw = function () {
      let proj = this.getProjection();
      if (proj == null) {
        return;
      }

      const sw = proj.fromLatLngToDivPixel(this.bounds.getSouthWest());
      const ne = proj.fromLatLngToDivPixel(this.bounds.getNorthEast());

      let style = this.element.style;

      Object.assign(style, {
        left: sw.x.toString() + 'px',
        top: ne.y.toString() + 'px',
        width: ne.x - sw.x + 'px',
        height: sw.y - ne.y + 'px',
      });
    };

    BoundedOverlayClass.prototype.setVisible = function (val) {
      if (val) {
        this.element.classList.remove('hidden');
      } else {
        this.element.classList.add('hidden');
      }
    };
  }

  let pane = options && options.pane ? options.pane : 'mapPane';

  return new BoundedOverlayClass(element, googleLatLngBounds, pane);
}

export default MapObjectFactory;
