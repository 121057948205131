<template>
  <div>
    <span v-show="finalImages && finalImages.length" class="thecarousel">
      <image-button
        v-for="image in finalImages"
        :key="image.rowPointer"
        :image="image"
        :tooltipPosition="tooltipPosition"
        @showFullscreenImage="(image) => (fullscreenImage = image)"
      />
    </span>
    <full-screen-image-modal
      v-if="fullscreenImage"
      :active="true"
      :image="fullscreenImage"
      :action="action"
      :actionTitle="actionTitle"
      @buttonClicked="executeAction($event)"
      @close="() => (fullscreenImage = null)"
    />
  </div>
</template>

<script>
import ImageButton from "./ImageButton.vue";
import FullScreenImageModal from "./FullScreenImageModal.vue";

export default {
  components: {
    ImageButton,
    FullScreenImageModal,
  },
  props: [
    "pickupRequestId",
    "driverStopRowPointer",
    "routeId",
    "pickupLocationId",
    "images",
    "tooltipPosition",
    "action",
    "actionTitle",
  ],
  data() {
    return {
      finalImages: [],
      fullscreenImage: null,
    };
  },
  watch: {
    pickupRequestId: "fetchImagesFromFilters",
    driverStopId: "fetchImagesFromFilters",
    routeId: "fetchImagesFromFilters",
    pickupLocationId: "fetchImagesFromFilters",
  },
  mounted() {
    if (this.images) {
      this.finalImages = this.images;
    } else {
      this.fetchImagesFromFilters();
    }
  },
  methods: {
    executeAction(image) {
      this.$emit("executeAction", image);
    },
    async fetchImagesFromFilters() {
      const filters = {};
      if (this.pickupLocationId) {
        filters.pickupLocationId = this.pickupLocationId;
      } else if (this.pickupRequestId) {
        filters.pickupRequestId = this.pickupRequestId;
      } else if (this.driverStopRowPointer) {
        filters.driverStopRowPointer = this.driverStopRowPointer;
      } else if (this.routeId) {
        filters.routeId = this.routeId;
      }

      const result = await this.$store.getters["images"](filters);

      // Update 'images', considering that there may be dupes
      {
        this.finalImages = [];

        const imageRowPointers = new Set();
        result.data.forEach((image) => {
          if (imageRowPointers.has(image.rowPointer)) {
            return;
          }

          imageRowPointers.add(image.rowPointer);

          this.finalImages.push(image);
        });
      }
    },
  },
};
</script>

<style scoped>
.thecarousel {
  vertical-align: middle;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
}
</style>
