<template>
    <pp4-marker v-for="m in markers" :key="m.key" :location="m.location" @click="removePoint(m.location)" 
        :icon="m.icon">
        <!-- <img src="@/assets/remove_icon.svg"> -->
    </pp4-marker>
</template>

<script setup>
import * as uuid from 'uuid'
import { computed, reactive, watch } from 'vue'

import Pp4Marker from "@/components/maps/Marker.vue";

const props = defineProps({
    points: {
        type: Array,
        required: true
    }
})

const data = reactive({
    remainingPoints: props.points.slice()
})

watch(() => props.points, () => {
    data.remainingPoints = props.points.slice()
})

const emit = defineEmits(['remove'])

function removePoint(latLng) {
    const index = data.remainingPoints.indexOf(latLng)

    const newVal = data.remainingPoints.slice()

    newVal.splice(index, 1)

    data.remainingPoints = newVal

    emit('remove', data.remainingPoints)
}

const markers = computed(() => {
    return data.remainingPoints.map(ll => {
        return {
            location: ll,
            key: uuid.v4(),
            icon: {
                url: '/remove_icon.svg'
            }
        }
    })
})
</script>