<template>
  <div class="app">
    <div v-if="loading">Signing in...</div>
    <div v-if="!loading && !$auth0.isAuthenticated.value">
      <b-button class="is-large" size="large" @click="login"
        >Login</b-button
      >
    </div>
    <router-view v-if="$auth0.isAuthenticated.value" />
  </div>
</template>

<script>
// import hotkeys from 'hotkeys-js';

export default {
  name: "App",
  components: {},
  data: function () {
    return {
      loading: false,
    };
  },
  computed: {
    auth: (vm) => vm.$auth,
  },
  provide() {
    return {};
  },
  methods: {
    login() {
      this.loading = true;
      this.$auth0.loginWithRedirect();
    },
  },
  watch: {
    "$auth0.isLoading.value"(newVal) {
      if (!newVal) {
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
  },
  mounted() {
    if (this.$auth0.isLoading) {
      this.loading = true;
    } else {
      this.loading = false;
    }
    // hotkeys.unbind('ctrl+alt+v');
    // hotkeys('ctrl+alt+v', (e) => {
    //   this.$router.push('vroom');
    // });
  },
};
</script>

<style lang="css">
/* html {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden !important;
} */

.app {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
}
</style>
